.mainClass {
  background: linear-gradient(90deg, #141e30, #243b55);
  width: 100%;
  height: 100%; 
  display: flex; 
  justify-content: center;
}

.profile {
    margin: 10%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

img {
  max-width: 50%;
  max-height: 100%;
  border-radius: 25%;
  display: block;
}
.profileContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

h1, h2{
    font-family: sans-serif;
    margin: 10px;
    text-wrap: nowrap;
    color: white;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.6); /* Hafif beyaz gölge */

}
.icons {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

@media screen and (max-width: 768px) {
    .profile {
        flex-direction: column-reverse;
        justify-content: center;
        gap: 50px;
    }    
  }